import { Button, Stack } from '@mui/material';
import { memo } from 'react';

const Footer = () => {
	return (
		<div className='footer'>
			<a href={process.env.REACT_APP_CONTACT_US}>
				<Button
					className='contact-us-button'
					variant='contained'
					type='submit'
				>
					Contact Us 📣
				</Button>
			</a>
			<p
				style={{
					marginTop: 40,
					marginBottom: 20,
				}}
			>
				© {2025} birdhaus Platforms, LLC. All Rights Reserved.
			</p>
			<Stack
				direction='row'
				alignContent='center'
				justifyContent='center'
				spacing={1}
				style={{marginTop: 20, maxWidth: "350px"}}
			>
				<a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=000f40f7-6bbe-4456-9758-32d4bde72439'>
					<span className='link'>Privacy Policy</span>
				</a>
				<a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=71c165d8-a9d6-4991-9af6-371f16ba897a'>
					<span className='link'>Terms and Conditions</span>
				</a>
			</Stack>
			<p
				style={{
					marginTop: 20,
					marginBottom: 20,
				}}
			>
				Made with ❤️ in NYC 🗽
			</p>
		</div>
	);
};

export default memo(Footer);
