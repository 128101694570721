import { Close as CloseIcon } from '@mui/icons-material';
import {
	Alert as MuiAlert,
	Box,
	Collapse,
	IconButton,
	Stack,
} from '@mui/material';
import { useState } from 'react';

import AppLogo from '../assets/images/app-logo.png';
import Decoration1 from '../assets/images/decoration-1.webp';
import Decoration2 from '../assets/images/decoration-2.webp';
import constants from '../utils/Constants';
import { Alert } from '../utils/types';
import Footer from './Footer';
import Hero from './Hero';
import SignupForm from './signup-form';

const Home = () => {
	const [signedUp, setSignedUp] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alert, setAlert] = useState<Alert>({
		severity: 'success',
		message: '',
	});

	const onSignup = () => {
		setSignedUp(true);
	};

	return (
		<>
			<Box
				sx={{
					width: '100vw',
					position: 'fixed',
					zIndex: 9999,
				}}
			>
				<Collapse
					in={showAlert}
					style={{
						padding: '1rem',
					}}
				>
					<MuiAlert
						style={{ position: 'relative' }}
						severity={alert.severity}
						action={
							<IconButton
								aria-label='close'
								color='inherit'
								size='small'
								onClick={() => setShowAlert(false)}
							>
								<CloseIcon fontSize='inherit' />
							</IconButton>
						}
					>
						{alert.message}
					</MuiAlert>
				</Collapse>
			</Box>

			<Stack
				spacing={2}
				direction={{
					xs: 'column',
					md: 'row',
				}}
			>
				<Stack>
					<div className='wrapper-A'>
						<Hero />
					</div>
				</Stack>

				<Stack justifyContent='center' alignItems='center'>
					<div className='wrapper-B'>
						<img
							className='decoration-1'
							src={Decoration1}
							alt='arrow 1'
						/>
						<img
							className='decoration-2'
							src={Decoration2}
							alt='arrow 2'
						/>
						<Stack
							spacing={0}
							justifyContent='center'
							alignItems='center'
							height='100%'
						>
							<Box
								maxWidth={{
									xs: '100%',
									md: '30%',
									lg: '120px',
									xl: '20%',
								}}
							>
								<img
									className='logo'
									alt='logo'
									src={AppLogo}
									width='100%'
									height='auto'
								/>
							</Box>
							{!signedUp ? (
								<Stack
									direction='column'
									alignItems='center'
									justifyContent='center'
								>
									<Stack>
										<h2>
											Sign up for{' '}
											<span className='bold name'>
												{constants.appName}
											</span>
											<sup>
												<span
													className='highlight-2'
													style={{
														fontSize: '1.15rem',
													}}
												>
													{' '}
													beta
												</span>
											</sup>
											<h4
												className='arrival'
												style={{ fontSize: '1.5rem' }}
											>
												Coming Spring 2025
											</h4>
										</h2>

										<SignupForm onSuccess={onSignup} />
										<Footer />
									</Stack>
								</Stack>
							) : (
								<Stack spacing={3} alignItems='center'>
									<h2>You're on the list!</h2>

									<p>
										Watch your inbox for updates, we can't
										wait to see you here!
									</p>
									<Footer />
								</Stack>
							)}
						</Stack>
					</div>
				</Stack>
			</Stack>
		</>
	);
};

export default Home;
